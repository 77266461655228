"use client"

import CharacterCount from "@tiptap/extension-character-count"
import { Link } from "@tiptap/extension-link"
import { Placeholder } from "@tiptap/extension-placeholder"
import { TableCell } from "@tiptap/extension-table-cell"
import { TableHeader } from "@tiptap/extension-table-header"
import { TableRow } from "@tiptap/extension-table-row"
import { TextAlign } from "@tiptap/extension-text-align"
import { Underline } from "@tiptap/extension-underline"
import StarterKit from "@tiptap/starter-kit"
import type { EditorProps } from "@tiptap/pm/view"
import { LinkBubbleMenuHandler, TableImproved } from "mui-tiptap"
import { useMemo } from "react"

import { type MentionSuggestion, setupSuggestion } from "./suggestions"
import type { RichTextEditorVariant } from "."
import type { EditorOptions, Extensions } from "@tiptap/react"
import { Mention } from "./extension/mention"

const CustomLinkExtension = Link.extend({
  inclusive: false,
})

export interface ExtensionProps {
  variant: RichTextEditorVariant
  placeholder?: string
  charLimit?: number
  suggestions?: MentionSuggestion[]
}

export const useEditor = (
  {
    variant = "document",
    placeholder = "",
    charLimit,
    suggestions,
  }: ExtensionProps = {
    variant: "document",
  },
): Pick<EditorOptions, "extensions" | "editorProps"> => {
  const extensions: Extensions = useMemo(
    () =>
      [
        TableImproved.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        ...(charLimit
          ? [
              CharacterCount.configure({
                limit: charLimit,
              }),
            ]
          : []),
        ...(suggestions?.length
          ? [
              Mention.configure({
                HTMLAttributes: {
                  class: "mention",
                },
                renderText({ node }) {
                  return `${node.attrs.label ?? node.attrs.id}`
                },
                suggestion: setupSuggestion(suggestions),
              }),
            ]
          : []),
        Placeholder.configure({
          placeholder,
        }),
        StarterKit.configure({
          codeBlock: false,
          horizontalRule: variant === "document" ? undefined : false,
          code: false,
          strike: false,
          hardBreak: false,
          heading:
            variant === "document"
              ? {
                  levels: [1, 2, 3, 4],
                }
              : false,
        }),
        Underline,
        TextAlign.configure({
          types:
            variant === "document" ? ["heading", "paragraph"] : ["paragraph"],
        }),
        CustomLinkExtension.configure({
          autolink: true,
          linkOnPaste: true,
          openOnClick: false,
        }),
        LinkBubbleMenuHandler,
      ] as Extensions,
    [charLimit, placeholder, suggestions, variant],
  )

  const editorProps: EditorProps = useMemo(() => ({}), [])

  return { extensions, editorProps } satisfies Pick<
    EditorOptions,
    "extensions" | "editorProps"
  >
}
