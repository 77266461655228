"use client"

import {
  MenuButtonAddTable,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonHorizontalRule,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuSelectTextAlign,
} from "mui-tiptap"
import type { RichTextEditorVariant } from "."
import MenuButtonSuggestion from "./MenuButtonSuggestion"
import type { MentionSuggestion } from "./suggestions"

interface RenderControlsProps {
  variant: RichTextEditorVariant
  suggestions?: MentionSuggestion[]
  hideUndoRedo?: boolean
  hideTable?: boolean
  hideTextAlign?: boolean
}

export const renderControls = (
  {
    variant = "document",
    suggestions = [],
    hideUndoRedo = false,
    hideTable = false,
    hideTextAlign = false,
  }: RenderControlsProps = {
    variant: "document",
  },
) => {
  return (
    <MenuControlsContainer>
      {variant === "document" && (
        <>
          <MenuSelectHeading />
          <MenuDivider />
        </>
      )}
      <MenuButtonEditLink />
      <MenuDivider />
      <MenuButtonBold />
      <MenuButtonItalic />
      <MenuButtonUnderline />
      <MenuDivider />
      <MenuButtonBulletedList />
      <MenuButtonOrderedList />
      {!hideTextAlign && (
        <>
          <MenuDivider />
          <MenuSelectTextAlign />
        </>
      )}
      {(variant === "document" || !hideTable) && <MenuDivider />}
      {variant === "document" && <MenuButtonHorizontalRule />}
      {!hideTable && <MenuButtonAddTable />}
      {!hideUndoRedo && (
        <>
          <MenuDivider />
          <MenuButtonUndo />
          <MenuButtonRedo />
        </>
      )}
      {suggestions.length > 0 && (
        <>
          <MenuDivider />
          <MenuButtonSuggestion suggestions={suggestions} />
        </>
      )}
    </MenuControlsContainer>
  )
}
