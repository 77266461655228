import { type CSSObject, styled, type Theme } from "@mui/material/styles"

import { Box } from "@/components/ui/layout/Box"
import { Drawer as MuiDrawer } from "@/components/ui/navigation/Drawer"
import { Stack } from "../../layout/Stack"
import { Paper } from "../../surfaces/Paper"

export const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(32),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(9),
})

export const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "size",
})<{
  size?: "medium" | "large"
}>(({ theme, size }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  height: size === "large" ? theme.spacing(8) : theme.spacing(6),
  boxShadow: "0px 0px 0px 1px #E0E0E0",
}))

export const DrawerWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: theme.palette.background.default,
  width: "100vw",
  height: "100vh",
  maxHeight: "100vh",
}))
export const DrawerChildrenStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.primary,
  height: "100%",
  ["& > *"]: {
    padding: theme.spacing(3),
  },
}))
export const DrawerPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  position: "relative",
}))

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: theme.spacing(32),
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))
