import { mergeAttributes, Node } from "@tiptap/core"
import type { DOMOutputSpec, Node as ProseMirrorNode } from "@tiptap/pm/model"
import { PluginKey } from "@tiptap/pm/state"
import Suggestion, { type SuggestionOptions } from "@tiptap/suggestion"

/**
 * Fork of https://github.com/moham96/tiptap/blob/6ff8323c72c081fb87607777b7d2a2a51da2af5b/packages/extension-mention/src/mention.ts
 *
 * As we introduced the following new features we had to fork the original extension:
 * - Support for custom node types (text or table)
 * - Drop support for option `renderLabel`
 */

export type MentionOptions = {
  HTMLAttributes: Record<string, unknown>
  renderText: (props: {
    options: MentionOptions
    node: ProseMirrorNode
  }) => string
  renderHTML: (props: {
    options: MentionOptions
    node: ProseMirrorNode
    HTMLAttributes: Record<string, unknown>
  }) => DOMOutputSpec
  suggestion: Omit<SuggestionOptions, "editor">
}

export const MentionPluginKey = new PluginKey("mention")

export const Mention = Node.create<MentionOptions>({
  name: "mention",

  addOptions() {
    return {
      HTMLAttributes: {},
      renderText({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
      },
      renderHTML({ options, node, HTMLAttributes }) {
        /**
         * TODO: this is very hacky, it would be better to configre the node type
         */
        const isBlock = node.attrs.mentionType === "table"
        const nodeStyle = isBlock ? "block py-2 text-center" : ""

        const text = options.renderText({ options, node })

        return [
          "span",
          mergeAttributes(
            { "data-type": node.type.name },
            options.HTMLAttributes,
            HTMLAttributes,
            {
              class: [
                HTMLAttributes.class,
                options.HTMLAttributes.class,
                nodeStyle,
              ]
                .filter(Boolean)
                .join(" "),
            },
          ),
          text,
        ]
      },
      suggestion: {
        char: "@",
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter?.text?.startsWith(" ")

          if (overrideSpace) {
            range.to += 1
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: "text",
                text: " ",
              },
            ])
            .run()

          window.getSelection()?.collapseToEnd()
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from)
          const type = state.schema.nodes[this.name]
          const allow = !!$from.parent.type.contentMatch.matchType(type)

          return allow
        },
      },
    }
  },

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {}
          }

          return {
            "data-id": attributes.id,
          }
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {}
          }

          return {
            "data-label": attributes.label,
          }
        },
      },

      payload: {
        default: "inline",
        parseHTML: (element) => element.getAttribute("data-payload"),
        renderHTML: (attributes) => {
          if (!attributes.payload) {
            return {}
          }

          return {
            "data-payload": attributes.payload,
          }
        },
      },

      mentionType: {
        default: "inline",
        parseHTML: (element) => element.getAttribute("data-node-type"),
        renderHTML: (attributes) => {
          if (!attributes.mentionType) {
            return {}
          }

          return {
            "data-node-type": attributes.mentionType,
          }
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    const html = this.options.renderHTML({
      options: this.options,
      node,
      HTMLAttributes,
    })

    if (typeof html === "string") {
      return [
        "span",
        mergeAttributes(
          { "data-type": this.name },
          this.options.HTMLAttributes,
          HTMLAttributes,
        ),
        html,
      ]
    }
    return html
  },

  renderText({ node }) {
    return this.options.renderText({
      options: this.options,
      node,
    })
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false
          const { selection } = state
          const { empty, anchor } = selection

          if (!empty) {
            return false
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true
              tr.insertText(
                this.options.suggestion.char || "",
                pos,
                pos + node.nodeSize,
              )

              return false
            }
          })

          return isMention
        }),
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})
