import DocumentIcon from "@/app/[locale]/(document)/_components/DocumentIcon"
import { isUserDataOfServiceProvider } from "@/app/[locale]/(user)/_utils"
import useAside from "@/app/_components/Aside/hooks/useAside"
import NotFoundError from "@/app/_components/NotFoundError"
import StaticBadge from "@/app/_components/StaticBadge"
import { trpc } from "@/app/_trpc/client"
import { List } from "@/components/ui/data-display/List"
import { ListItemButton } from "@/components/ui/data-display/ListItemButton"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import { useTranslation } from "@/i18n"
import type { NotificationRouter } from "@/server/routers/notification"
import { getDayjsDateTimeFormat } from "@/utils/format"
import { LibConstants, useGetUserSessionData } from "@/utils/lib"
import { replaceSlugs } from "@/utils/navigation"
import { mdiBell, mdiCheckCircle } from "@mdi/js"
import Icon from "@mdi/react"
import { CircularProgress, type StackProps } from "@mui/material"
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar"
import { type Theme, useTheme } from "@mui/material/styles"
import { useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import type { inferRouterOutputs } from "@trpc/server"
import { useIntersectionObserver } from "@uidotdev/usehooks"
import dayjs from "dayjs"
import { useParams, usePathname, useRouter } from "next/navigation"
import { useCallback, useEffect, useMemo } from "react"
import de from "../../_messages/de.json"

type NitificationData =
  inferRouterOutputs<NotificationRouter>["list"]["results"][number]

function isSupportedNotification(notification: NitificationData) {
  return (
    notification.category &&
    ["task_assigned", "task_unassigned"].includes(notification.category)
  )
}

function getNotificationTitle(notification: NitificationData) {
  if (notification.category === "task_assigned") {
    if (notification?.task?.document) {
      return "notifictaions.popper.notificationTitle.assignedTaskWithDocument"
    }
    return "notifictaions.popper.notificationTitle.assignedTask"
  }
  if (notification.category === "task_unassigned") {
    return "notifictaions.popper.notificationTitle.unassignedTask"
  }
  return "notifictaions.popper.notificationTitle.unknownNotification"
}

function getNotificationAvatar(notification: NitificationData, theme: Theme) {
  if (notification?.task?.document)
    return <DocumentIcon category={notification?.task?.document?.category} />
  if (!notification?.task?.document)
    return (
      <Icon
        path={mdiCheckCircle}
        size={1}
        color={theme.palette.action.active}
      />
    )
  return <Icon path={mdiBell} size={1} color={theme.palette.action.active} />
}

export interface NotificationListProps extends StackProps {
  onClick?: () => void
}

export default function NotificationList({
  onClick = () => {},
  ...stackProps
}: NotificationListProps) {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const { t, tHtml } = useTranslation(de)
  const router = useRouter()
  const pathname = usePathname()
  const params = useParams()
  const { createAsideSearch } = useAside()
  const [scrollEndRef, entry] = useIntersectionObserver<HTMLDivElement>({
    threshold: 0,
    root: null,
  })
  const isScrollEndInView = entry?.isIntersecting ?? false

  const {
    data: { data: userData } = {},
    error: errorUserData,
  } = useGetUserSessionData()
  const userOfServiceProvider = isUserDataOfServiceProvider(userData)

  const notificationsQuery = trpc.notification.list.useInfiniteQuery(
    {
      limit: LibConstants.defaultLimit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    },
  )
  const notifications = useMemo(
    () =>
      notificationsQuery.data?.pages
        .flatMap((page) => page.results ?? [])
        .filter(isSupportedNotification) ?? [],
    [notificationsQuery.data?.pages],
  )
  const loading = notificationsQuery.status === "pending"
  const isEmpty = !loading && notificationsQuery.data?.pages?.at(0)?.total === 0

  const acknowledgeMutation = trpc.notification.acknowledge.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.notification.list),
      })
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.notification.check),
      })
    },
  })

  const handleNotificationClick = useCallback(
    (notification: NitificationData) => {
      const caseId = notification?.task?.case?.id
      const taskId = notification?.task?.id
      const search = createAsideSearch("task", taskId)
      const userRoleRoute = userOfServiceProvider ? "cardealer" : "lawyer"
      const userRoleRouteParam = userOfServiceProvider
        ? "cardealerId"
        : "lawyerId"

      acknowledgeMutation.mutate({
        id: notification.id,
      })
      onClick()

      if (caseId) {
        router.push(
          replaceSlugs(
            `/[locale]/${userRoleRoute}/[${userRoleRouteParam}]/cases/[caseId]/tasks`,
            { ...params, caseId },
            search,
          ),
        )
      } else {
        router.push(`${pathname}?${search}`)
      }
    },
    [
      acknowledgeMutation,
      createAsideSearch,
      onClick,
      params,
      pathname,
      router,
      userOfServiceProvider,
    ],
  )

  useEffect(() => {
    if (
      isScrollEndInView &&
      !notificationsQuery.isFetching &&
      notificationsQuery.hasNextPage
    ) {
      notificationsQuery.fetchNextPage()
    }
  }, [notificationsQuery, isScrollEndInView])

  if (notificationsQuery.error || errorUserData) {
    throw notificationsQuery.error ?? errorUserData
  }
  return (
    <Stack className={"overflow-y-auto h-full max-h-[32rem]"} {...stackProps}>
      {isEmpty && (
        <NotFoundError
          customMessage={t("notifictaions.popper.noNotifications")}
        />
      )}
      {!isEmpty && (
        <List
          className="py-0"
          dense
          sx={{
            minWidth: 300,
            maxWidth: stackProps.maxWidth,
          }}
        >
          {notifications.map((notification) => (
            <ListItemButton
              key={notification.id}
              data-testid="notification-item"
              onClick={handleNotificationClick.bind(null, notification)}
            >
              <ListItemAvatar>
                {getNotificationAvatar(notification, theme)}
              </ListItemAvatar>
              <ListItemText
                primary={tHtml(getNotificationTitle(notification), {
                  name: notification?.task?.name ?? "",
                  document: notification?.task?.document?.name ?? "",
                })}
                secondary={dayjs(notification.created_at).format(
                  getDayjsDateTimeFormat(),
                )}
              />
              {!notification.read && (
                <StaticBadge
                  variant="primary"
                  size="small"
                  badgeContent={" "}
                />
              )}
            </ListItemButton>
          ))}
          {!notificationsQuery.isFetching && (
            <div className="w-0 h-0" ref={scrollEndRef} />
          )}
        </List>
      )}
      {!isEmpty && notificationsQuery.isFetching && (
        <Box className="flex justify-center" p={4}>
          <CircularProgress color="info" />
        </Box>
      )}
    </Stack>
  )
}
