import { mdiChat, mdiEmail } from "@mdi/js"
import type {
  communication_channel_type,
  default_conversation_type,
} from "dcp-types"
import colors from "tailwindcss/colors"

export function isDefaultConversationType(
  conversationSlug?: string | default_conversation_type | null,
): conversationSlug is default_conversation_type {
  return (
    conversationSlug === "claimant_service_providers" ||
    conversationSlug === "internal"
  )
}

export const participantIsNotOwnOrganization = (
  participant: {
    person?: { organization?: { id: string } | null } | null
    organization?: { id: string } | null
  } | null,
  userOrganizationId?: string,
) => {
  return participant?.person
    ? participant?.person?.organization?.id !== userOrganizationId
    : participant?.organization?.id !== userOrganizationId
}

export const getIconByChannel = (
  channel: communication_channel_type | null,
) => {
  switch (channel) {
    case "email":
      return mdiEmail
    default:
      return mdiChat
  }
}

export const getColorByChannel = (
  channel: communication_channel_type | null,
) => {
  switch (channel) {
    case "email":
      return colors.blue[500]
    case "chat":
      return colors.green[500]
    default:
      return undefined
  }
}
