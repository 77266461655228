import { useState, useCallback, useEffect } from "react"

export default function useForceRerender(
  // we have to use any, as this is an agnistic hook
  value: unknown,
  options?: { delay?: number; disable?: boolean },
) {
  const [, updateState] = useState<object | null>(null)
  const forceUpdate = useCallback(() => updateState({}), [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: we keep "value" as dependency as the value change is the trigger for force update
  useEffect(() => {
    if (options?.disable === true) {
      return
    }

    if (options?.delay) {
      setTimeout(() => {
        forceUpdate()
      }, options?.delay ?? 0)
    } else {
      forceUpdate()
    }
  }, [value, options, forceUpdate])

  return forceUpdate
}
