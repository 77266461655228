"use client"

import OrganizationChip from "@/app/[locale]/(organization)/_components/OrganizationChip"
import PersonChip from "@/app/[locale]/(person)/_components/PersonChip"
import { isUserDataOfServiceProvider } from "@/app/[locale]/(user)/_utils"
import useAside from "@/app/_components/Aside/hooks/useAside"
import NotFoundError from "@/app/_components/NotFoundError"
import { trpc } from "@/app/_trpc/client"
import { List } from "@/components/ui/data-display/List"
import { ListItemButton } from "@/components/ui/data-display/ListItemButton"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Typography } from "@/components/ui/data-display/Typography"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import type { ArrayElement } from "@/global"
import { useTranslation, type TranslateFunction } from "@/i18n"
import type { MessageRouter } from "@/server/routers/messages"
import { DEFAULT_PAGINATION_LIMIT } from "@/server/routers/messages/constants"
import { getDayjsDateTimeFormat } from "@/utils/format"
import { useGetUserSessionData } from "@/utils/lib"
import { replaceSlugs } from "@/utils/navigation"
import { useEditor } from "@/utils/richTextEditor"
import { mdiChat, mdiChevronRight, mdiEmail } from "@mdi/js"
import Icon from "@mdi/react"
import { CircularProgress, type StackProps } from "@mui/material"
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar"
import { useTheme, type Theme } from "@mui/material/styles"
import type { inferRouterOutputs } from "@trpc/server"
import { useIntersectionObserver } from "@uidotdev/usehooks"
import dayjs from "dayjs"
import { RichTextReadOnly } from "mui-tiptap"
import { useParams, useRouter } from "next/navigation"
import { useCallback, useEffect, useMemo } from "react"
import de from "../../_messages/de.json"
import { isDefaultConversationType } from "../../_utils"
import { CHAT_CONTENT_PREVIEW_LENGTH } from "../Message"

type Notification = ArrayElement<
  inferRouterOutputs<MessageRouter>["getNotifications"]["results"]
>

/**
 * TODO: emojis are not displayed correctly always, identify why and fix it
 */
function getNotificationAvatar(notification: Notification, theme: Theme) {
  if (notification.reaction) {
    // check if reaction is a unicode number (only numbers 0-9)
    const isUnicodeNumber = /^\d+$/.test(notification.reaction.reaction)
    const reaction = isUnicodeNumber
      ? String.fromCodePoint(
          Number.parseInt(notification.reaction.reaction, 16),
        )
      : notification.reaction.reaction

    return (
      <Typography variant="h6" component="div">
        {reaction}
      </Typography>
    )
  }

  if (notification.email_message) {
    return <Icon path={mdiEmail} size={1} color={theme.palette.action.active} />
  }

  return <Icon path={mdiChat} size={1} color={theme.palette.action.active} />
}

export interface MessageNotificationListProps extends StackProps {
  onClick?: () => void
}

export default function MessageNotificationList({
  onClick,
  ...stackProps
}: MessageNotificationListProps) {
  const theme = useTheme()
  const { t } = useTranslation(de)
  const router = useRouter()
  const params = useParams()
  const { asideSearch } = useAside()

  const [scrollEndRef, entry] = useIntersectionObserver<HTMLDivElement>({
    threshold: 0,
    root: null,
  })
  const isScrollEndInView = entry?.isIntersecting ?? false
  const { extensions, editorProps } = useEditor({
    variant: "chat",
  })

  const {
    data: { data: userData } = {},
    error: errorUserData,
  } = useGetUserSessionData()

  const userOfServiceProvider = isUserDataOfServiceProvider(userData)

  const notificationsQuery = trpc.messages.getNotifications.useInfiniteQuery(
    {
      limit: DEFAULT_PAGINATION_LIMIT.Notifications,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    },
  )

  const notifications = useMemo(() => {
    const collection =
      notificationsQuery.data?.pages.flatMap((page) => page.results ?? []) ?? []
    return collection.map((notification) => {
      const senderOrganization =
        notification.email_message?.organization ||
        (notification.reaction
          ? notification.reaction.sender_person?.organization
          : notification.message?.organization)
      const senderPerson =
        notification.email_message?.person ||
        (notification.reaction
          ? notification.reaction.sender_person
          : notification.message?.person)
      return { notification, senderOrganization, senderPerson }
    })
  }, [notificationsQuery.data?.pages])

  const loading = notificationsQuery.status === "pending"
  const isEmpty = !loading && notificationsQuery.data?.pages?.at(0)?.total === 0

  const getMessagePreview = useCallback(
    (notification: Notification, t: TranslateFunction<typeof de>) => {
      if (notification.reaction) {
        return (
          <Typography color="text.secondary">
            {t("notifictaions.popper.notificationTitle.newReaction")}
          </Typography>
        )
      }

      if (notification.message) {
        if (
          notification.message?.content &&
          notification.message?.content.trim().length >
            CHAT_CONTENT_PREVIEW_LENGTH / 3
        ) {
          return `${notification.message.content
            .trim()
            .slice(0, CHAT_CONTENT_PREVIEW_LENGTH / 3 - 3)}...`
        }
        return (
          <RichTextReadOnly
            content={notification.message?.content ?? ""}
            extensions={extensions}
            editorProps={editorProps}
          />
        )
      }

      if (notification.email_message) {
        if (
          notification.email_message?.email?.subject &&
          notification.email_message?.email?.subject?.trim().length >
            CHAT_CONTENT_PREVIEW_LENGTH / 3
        ) {
          return `${notification.email_message.email.subject
            .trim()
            .slice(0, CHAT_CONTENT_PREVIEW_LENGTH / 3 - 3)}...`
        }
      }

      return (
        <RichTextReadOnly
          content={notification.email_message?.email?.subject ?? ""}
          extensions={extensions}
          editorProps={editorProps}
        />
      )
    },
    [extensions, editorProps],
  )

  const handleNotificationClick = useCallback(
    (notification: Notification) => {
      const conversationId = notification?.conversation_id
      const conversationType = notification?.message?.conversation?.type
      const caseId = notification?.case?.id
      const userRoleRoute = userOfServiceProvider ? "cardealer" : "lawyer"
      const userRoleRouteParam = userOfServiceProvider
        ? "cardealerId"
        : "lawyerId"

      if (caseId && isDefaultConversationType(conversationType)) {
        onClick?.()
        router.push(
          replaceSlugs(
            `/[locale]/${userRoleRoute}/[${userRoleRouteParam}]/cases/[caseId]/messages/[conversationSlug]`,
            { ...params, caseId, conversationSlug: conversationType },
            asideSearch,
          ),
        )
      } else if (caseId && conversationId) {
        onClick?.()
        router.push(
          replaceSlugs(
            `/[locale]/${userRoleRoute}/[${userRoleRouteParam}]/cases/[caseId]/messages/[conversationSlug]`,
            { ...params, caseId, conversationSlug: conversationId },
            asideSearch,
          ),
        )
      } else {
        // TODO: route to unassigned message page
      }
    },
    [onClick, params, router, asideSearch, userOfServiceProvider],
  )

  useEffect(() => {
    if (
      isScrollEndInView &&
      !notificationsQuery.isFetching &&
      notificationsQuery.hasNextPage
    ) {
      notificationsQuery.fetchNextPage()
    }
  }, [notificationsQuery, isScrollEndInView])

  if (notificationsQuery.error || errorUserData) {
    throw notificationsQuery.error ?? errorUserData
  }
  return (
    <Stack className={"overflow-y-auto h-full max-h-[32rem]"} {...stackProps}>
      {isEmpty && (
        <NotFoundError
          customMessage={t("notifictaions.popper.noNotifications")}
        />
      )}
      {!isEmpty && (
        <List
          className="py-0"
          dense
          sx={{
            minWidth: 300,
            maxWidth: stackProps.maxWidth,
          }}
        >
          {notifications.map(
            ({ notification, senderOrganization, senderPerson }) => (
              <ListItemButton
                key={notification.id}
                data-testid="chat-notification-item"
                onClick={handleNotificationClick.bind(null, notification)}
              >
                <ListItemAvatar>
                  {getNotificationAvatar(notification, theme)}
                </ListItemAvatar>
                <ListItemText<"span", "div">
                  primary={getMessagePreview(notification, t)}
                  secondary={
                    <Stack spacing={0.5} pt={0.5}>
                      <Stack direction="row" alignItems="center">
                        <OrganizationChip
                          size="tiny"
                          organization={senderOrganization}
                        />
                        {senderOrganization && senderPerson && (
                          <Icon
                            path={mdiChevronRight}
                            size={0.75}
                            color={theme.palette.action.active}
                          />
                        )}
                        <PersonChip size="tiny" person={senderPerson} />
                      </Stack>
                      <Typography variant="caption">
                        {dayjs(notification.created_at).format(
                          getDayjsDateTimeFormat(),
                        )}
                      </Typography>
                    </Stack>
                  }
                  secondaryTypographyProps={{
                    paragraph: false,
                    component: "div",
                  }}
                />
              </ListItemButton>
            ),
          )}
          {!notificationsQuery.isFetching && (
            <div className="w-0 h-0" ref={scrollEndRef} />
          )}
        </List>
      )}
      {!isEmpty && notificationsQuery.isFetching && (
        <Box className="flex justify-center" p={4}>
          <CircularProgress color="info" />
        </Box>
      )}
    </Stack>
  )
}
